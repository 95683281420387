import { BaseModal, Card, Icon, IconName, Theme, useTw } from '@mea-menu/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, Text, View } from 'react-native'
import { EmployeeAuthService } from '../../api/employee/EmployeeAuthService'
import { backendClient } from '../../api/HTTPClient'
import { config } from '../../config'
import { SelectLanguageFragment } from '../../fragments/SelectLanguageFragment'
import { useRestaurantStore, useSessionStore, useThemeStore, useTooltipStore, useUserStore } from '../../store'
import { reinitializeStores } from '../../types/utils'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'

const SUPPORT_EMAIL = 'info@meamenu.com'

export interface SettingsModalProps {
  visible: boolean
  onClose: () => void
}

type Mode = 'language'

export const SettingsItem = ({
  text,
  iconName,
  onPress,
}: {
  text: string
  iconName: IconName
  onPress: () => void
}) => {
  const { tw } = useTw()
  return (
    <Card style={tw`px-md py-md rounded-md mt-md flex-row justify-between items-center`} onPress={onPress}>
      <Text style={tw`flex-10 textMono`} numberOfLines={1}>
        {text}
      </Text>
      <Icon name={iconName} />
    </Card>
  )
}

export function SettingsModal({ onClose, visible }: SettingsModalProps) {
  const { tw, theme } = useTw()
  const { t, i18n } = useTranslation()
  const { restaurant } = useRestaurantStore()
  const { setInputLanguage, setAppInitialized } = useSessionStore()
  const { setColorMode } = useThemeStore()
  const { employee } = useUserStore()
  const [mode, setmode] = useState<Mode>()
  const { clearShownTooltipStore, clearTooltip } = useTooltipStore()

  const restartTutorial = () => {
    clearShownTooltipStore()
    clearTooltip()
    onClose()
    setAppInitialized(false)
    setTimeout(
      () => {
        setAppInitialized(true)
      },
      __DEV__ ? 500 : 2500
    )
  }

  return (
    <BaseModal
      onClose={() => {
        onClose()
        setmode(undefined)
      }}
      title={t('l.settings')}
      visible={visible}
    >
      <View>
        {!mode && (
          <View>
            <SettingsItem onPress={() => setmode('language')} text={t('l.changeLanguage')} iconName={'Language'} />
            <SettingsItem
              onPress={() => {
                setColorMode(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT)
                onClose()
              }}
              text={theme === Theme.DARK ? t('l.turnToLightMode') : t('l.turnToDarkMode')}
              iconName={theme === Theme.DARK ? 'Sun' : 'Moon'}
            />
            <SettingsItem onPress={restartTutorial} text={t('l.restartTooltipTour')} iconName={'Info'} />

            {employee && (
              <SettingsItem
                onPress={async () => {
                  onClose()
                  try {
                    await EmployeeAuthService.logoutEmployee(
                      backendClient.defaults.headers.common['Authorization'] as string
                    )
                    reinitializeStores()
                  } catch (e) {
                    meaErrorHandler(e, true)
                  }
                }}
                text={t('l.logout')}
                iconName={'Close'}
              />
            )}
          </View>
        )}

        {mode === 'language' && (
          <SelectLanguageFragment
            currentLanguage={i18n.language}
            onLanguageSelected={langCode => {
              i18n.changeLanguage(langCode)
              setInputLanguage(langCode)
            }}
            onClose={() => {
              onClose()
              setmode(undefined)
            }}
          />
        )}
      </View>
      <View style={tw`flex-1 flex-row justify-between items-center`}>
        {restaurant && (
          <Text
            style={tw`textMono caption mt-md py-sm px-xs`}
            onPress={() =>
              Linking.openURL(
                `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(
                  t('l.supportForRestaurant', { restaurantName: restaurant.name })
                )}&body=${encodeURIComponent(t('l.supportMailBody', { restaurantName: restaurant.name }))}`
              )
            }
          >
            {`${t('l.support')}: `}
            <Text style={tw`textMono caption mt-md underline`}>{SUPPORT_EMAIL}</Text>
          </Text>
        )}
        <Text style={tw`textMono caption mt-md opacity-50`}>v{config.version}</Text>
      </View>
    </BaseModal>
  )
}
