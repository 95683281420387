import {
  BaseModal,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Card,
  Icon,
  IconButton,
  IconButtonShape,
  IconColor,
  useTw,
} from '@mea-menu/components'
import { useIsFocused } from '@react-navigation/native'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { FlatList, Text, View } from 'react-native'
import Toast from 'react-native-root-toast'
import { DishCategoryEntityService } from '../api/employee/DishCategoryEntityService'
import { DishEntityService, UpdateMenuSortingItem } from '../api/employee/DishEntityService'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { isAxiosErrorFromDomainWithInternalCode } from '../api/HTTPClient'
import { CategoryName, DishSmartOptionsModal, LoadingIndicator, MenuDishItem, Screen } from '../components'
import { CATEGORY_NAME_ONE_LINE_HEIGHT } from '../components/CategoryName'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { MenuStackScreenProps } from '../navigation'
import { useMenuStore, useRestaurantStore, useSessionStore } from '../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'
import { Dish, DishCategory, MeaError, NotAuthorizedCode, TooltipOrderManageMenuScreenTypes } from '../types'
import { getLocalizedField, getNextStringLiteralEnumValue } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { deepEquals } from '../utils/commonHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface ManageMenuScreenProps {
  menuId: string
}

const ADD_CAT_SUBCAT_HEIGHT = 32
const CAT_SUBCAT_NAME_MIN_LENGTH = 3

type SubCategory = { name: string; key: string; dishIds: string[] }
type Category = { name: string; key: string; subCatKeys: string[] }

type ListData = {
  dishes: { [dishId: string]: Dish }
  subCategories: { [subCatKey: string]: SubCategory }
  categories: { [catKey: string]: Category }
  categoryOrder: string[]
}

enum DragEditType {
  OFF = 'Off',
  DISHES = 'Dishes',
  CAT_SUBCAT = 'CatSubcat',
}

enum DragDroppableType {
  CATEGORY = 'category',
  SUBCATEGORY = 'subCategory',
  DISH = 'dish',
}

export const ManageMenuScreen = ({ navigation, route }: MenuStackScreenProps<'ManageMenuScreen'>) => {
  const { menuId } = route.params ?? {}

  const { tw } = useTw()
  const { t, i18n } = useTranslation()
  const { restaurant } = useRestaurantStore()
  const { menu, fetchDishes, setMenu } = useMenuStore()
  const { inputLanguage, setInputLanguage, getAllOtherInputLanguages } = useSessionStore()

  const focused = useIsFocused()
  const [originalListData, setOriginalListData] = useState<ListData>({
    dishes: {},
    subCategories: {},
    categories: {},
    categoryOrder: [],
  })
  const [editingListData, setEditingListData] = useState<ListData>()
  const [addingCatSubcat, setAddingCatSubcat] = useState<'category' | string>()
  const [renamingCatSubcat, setRenamingCatSubcat] = useState<string>()
  const [smartDishModal, setSmartDishModal] = useState<Dish>()
  const [currentDragEditType, setCurrentDragEditType] = useState<DragEditType>(DragEditType.OFF)
  const [currentScrollPosY, setCurrentScrollPosY] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const scrollerRef = useRef<HTMLDivElement>(null)

  const listData: ListData = editingListData ?? originalListData

  const updateSorting = async () => {
    if (!editingListData) return
    try {
      const flatDishes: UpdateMenuSortingItem[] = []
      editingListData.categoryOrder.forEach(dishCategoryKey => {
        editingListData.categories[dishCategoryKey].subCatKeys.forEach(dishSubCategoryKey => {
          editingListData.subCategories[dishSubCategoryKey].dishIds.forEach(dishId => {
            flatDishes.push({
              dishId,
              dishCategoryKey,
              dishSubCategoryKey: !dishSubCategoryKey.endsWith('.others') ? dishSubCategoryKey : undefined,
            })
          })
        })
      })
      const updateSortingResult = await DishEntityService.updateMenuSorting({ orderedDishes: flatDishes })
      if (!updateSortingResult) throw new MeaError('cannotUpdateMenuSorting', t('errors.cannotUpdateMenuSorting'))
      await fetchDishes()
      setOriginalListData(editingListData)
      setEditingListData(undefined)
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  useEffect(() => {
    if (!focused && editingListData) {
      setEditingListData(undefined)
    } else if (!editingListData && focused && currentScrollPosY !== undefined) {
      setTimeout(() => {
        scrollerRef.current?.scrollTo({ top: currentScrollPosY, behavior: 'smooth' })
        setCurrentScrollPosY(undefined)
      }, 100)
    }
  }, [focused, editingListData])

  useEffect(() => {
    if (!editingListData && currentScrollPosY !== undefined) {
      scrollerRef.current?.scrollTo({ top: currentScrollPosY })
      setCurrentScrollPosY(undefined)
      if (loading) setLoading(false)
    }
    if (!originalListData || !editingListData || deepEquals(originalListData, editingListData)) return
    updateSorting()
  }, [editingListData, originalListData])

  useEffect(() => {
    if (!menu) return
    const result: ListData = {
      dishes: {},
      subCategories: {},
      categories: {},
      categoryOrder: [],
    }
    if (menu.dishes.length === 0) return

    menu.dishes.forEach(dish => {
      result.dishes[dish._id] = dish
      const subCatKey = dish.dishSubCategory ?? dish.dishCategory + '.others'

      if (result.subCategories[subCatKey]) {
        result.subCategories[subCatKey].dishIds.push(dish._id)
      } else {
        result.subCategories[subCatKey] = { key: subCatKey, name: subCatKey, dishIds: [dish._id] }
      }
    })

    Object.values(result.subCategories).forEach(({ key: subCatKey }) => {
      const catKey = subCatKey.split('.')[0]
      if (result.categories[catKey]) {
        result.categories[catKey].subCatKeys.push(subCatKey)
      } else {
        result.categories[catKey] = { key: catKey, name: catKey, subCatKeys: [subCatKey] }
      }
    })

    result.categoryOrder = Object.keys(result.categories)

    setOriginalListData(result)
  }, [menu?.dishes])

  useEffect(() => {
    if (currentDragEditType === DragEditType.OFF) return
    showToast(
      currentDragEditType === DragEditType.CAT_SUBCAT ? t('l.catSubcatOrdering') : t('l.dishesOrdering'),
      'SUCCESS',
      2000,
      Toast.positions.BOTTOM
    )
  }, [currentDragEditType])

  const CategoryNameView = ({ catSubcat }: { catSubcat: Category | SubCategory }) => {
    const openRenameCatSubcatModal = () => {
      if (currentDragEditType === DragEditType.OFF) setRenamingCatSubcat(catSubcat.key)
    }

    const EditButton = () => (
      <IconButton
        icon="Edit"
        size={ButtonSize.Small}
        variant={ButtonVariant.Accent}
        onPress={openRenameCatSubcatModal}
        style={tw`ml-xs`}
      />
    )

    return (
      <Card
        style={tw`flex-row h-[${CATEGORY_NAME_ONE_LINE_HEIGHT + 20}px] justify-between items-center pr-xs ${
          currentDragEditType !== DragEditType.CAT_SUBCAT ? 'mb-xs' : ''
        }`}
        onPress={openRenameCatSubcatModal}
      >
        <CategoryName categoryKey={catSubcat.key} catSubcatSeparator={'dash'} />
        {currentDragEditType === DragEditType.OFF && !catSubcat.key.endsWith('.others') && (
          <>
            {listData.categoryOrder.length > 0 && listData.categoryOrder[0] === catSubcat.key ? (
              <MeaTooltip
                uniqueId={TooltipOrderManageMenuScreenTypes.CATEGORY_RENAME}
                text={t('tooltips.renameCategory')}
              >
                <EditButton />
              </MeaTooltip>
            ) : (
              <EditButton />
            )}
          </>
        )}
      </Card>
    )
  }

  const CategoryView = ({
    index,
    category,
    subCategories,
  }: {
    index: number
    category: Category
    subCategories: SubCategory[]
  }) => {
    const AddSubcategory = () => {
      return (
        <Button
          label={t('l.subcategory')}
          variant={ButtonVariant.SecondaryLight}
          size={ButtonSize.Small}
          icon="Plus"
          onPress={() => {
            if (editingListData) return showToast(t('l.pleaseWait'), 'ERROR')
            setInputLanguage(i18n.language)
            setAddingCatSubcat(category.key)
          }}
        />
      )
    }

    const AddDish = () => {
      return (
        <Button
          style={tw`ml-xs`}
          label={t('l.dish')}
          variant={ButtonVariant.SecondaryLight}
          size={ButtonSize.Small}
          icon="Plus"
          onPress={() => {
            if (editingListData) return showToast(t('l.pleaseWait'), 'ERROR')
            addDish(category.key)
          }}
        />
      )
    }

    const isDraggable = !editingListData && currentDragEditType === DragEditType.CAT_SUBCAT

    return (
      <Draggable draggableId={category.key} index={index} isDragDisabled={!isDraggable}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...tw`p-xs rounded-md ${snapshot.isDragging ? 'fillCardAccent' : ''}`,
              ...provided.draggableProps.style,
            }}
          >
            <View style={tw`flex-row items-center mr-xs`}>
              {isDraggable && (
                <>
                  <div {...provided.dragHandleProps} style={tw`py-xs`}>
                    <Icon name={'DragHandle'} color={IconColor.mono} />
                  </div>
                  <View style={tw`w-[8px]`} />
                </>
              )}
              <CategoryNameView catSubcat={category} />
            </View>
            {currentDragEditType === DragEditType.OFF && (
              <View style={tw`flex-row h-[${ADD_CAT_SUBCAT_HEIGHT}px] mr-sm`}>
                {listData.categoryOrder.length > 0 && listData.categoryOrder[0] === category.key ? (
                  <MeaTooltip
                    uniqueId={TooltipOrderManageMenuScreenTypes.SUBCATEGORY_ADD}
                    text={t('tooltips.addSubcategory')}
                  >
                    <AddSubcategory />
                  </MeaTooltip>
                ) : (
                  <AddSubcategory />
                )}
                {listData.categoryOrder.length > 0 && listData.categoryOrder[0] === category.key ? (
                  <MeaTooltip uniqueId={TooltipOrderManageMenuScreenTypes.DISH_ADD} text={t('tooltips.addDish')}>
                    <AddDish />
                  </MeaTooltip>
                ) : (
                  <AddDish />
                )}
              </View>
            )}
            <Droppable droppableId={category.key} type={DragDroppableType.SUBCATEGORY}>
              {provided => (
                <div ref={provided.innerRef} style={tw`mt-2 ml-xs grow`} {...provided.droppableProps}>
                  {subCategories.map((subCategory, index) => (
                    <SubCategoryView
                      key={subCategory.key}
                      index={index}
                      hideName={subCategories.length === 1}
                      subCategory={subCategory}
                      dishes={subCategory.dishIds.map(dishId => listData.dishes[dishId])}
                    />
                  ))}
                  {provided.placeholder as React.ReactNode}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    )
  }

  const SubCategoryView = ({
    index,
    subCategory,
    hideName,
    dishes,
  }: {
    index: number
    subCategory: SubCategory
    hideName: boolean
    dishes: Dish[]
  }) => {
    const isDraggable = !editingListData && currentDragEditType === DragEditType.CAT_SUBCAT

    return (
      <Draggable draggableId={subCategory.key} index={index} isDragDisabled={!isDraggable}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...tw`border-[1px] p-xs strokeMono ${snapshot.isDragging ? 'fillCardAccent rounded-md' : ''}`,
              ...provided.draggableProps.style,
            }}
          >
            <View style={tw`flex-row items-center ${hideName ? 'hidden' : ''}`}>
              {isDraggable && (
                <>
                  <div {...provided.dragHandleProps} style={tw`py-xs`}>
                    <Icon name={'DragHandle'} color={IconColor.mono} />
                  </div>
                  <View style={tw`w-[6px]`} />
                </>
              )}
              <CategoryNameView catSubcat={subCategory} />
            </View>
            {currentDragEditType !== DragEditType.CAT_SUBCAT && (
              <Droppable droppableId={subCategory.key} type={DragDroppableType.DISH}>
                {provided => (
                  <div ref={provided.innerRef} style={tw`grow`} {...provided.droppableProps}>
                    {dishes.map((dish, index) => (
                      <DishView key={index} dish={dish} index={index} />
                    ))}
                    {dishes.length === 0 && (
                      <View style={tw`h-[90px] fillCard justify-center rounded-md`}>
                        <Text style={tw`textMono text-center title4`}>
                          {currentDragEditType === DragEditType.OFF
                            ? t('l.switchAndDragDishPlaceholder')
                            : t('l.dragDishPlaceholder')}
                        </Text>
                      </View>
                    )}
                    {provided.placeholder as React.ReactNode}
                  </div>
                )}
              </Droppable>
            )}
          </div>
        )}
      </Draggable>
    )
  }

  const DishView = ({ dish, index }: { dish: Dish; index: number }) => {
    const goToEditScreen = async () => {
      if (editingListData) return showToast(t('l.pleaseWait'), 'ERROR')
      const navProps = { dishId: dish._id }
      setCurrentScrollPosY(scrollerRef.current?.scrollTop ?? 0)
      navigation.navigate('DishEditorScreen', navProps)
    }

    const isDraggable = !editingListData && currentDragEditType === DragEditType.DISHES

    return (
      <Draggable draggableId={dish._id} index={index} isDragDisabled={!isDraggable}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <View style={tw`flex-row items-center`}>
              {isDraggable && (
                <>
                  <div {...provided.dragHandleProps} style={tw`py-xs`}>
                    <Icon name={'DragHandle'} color={IconColor.mono} />
                  </div>
                  <View style={tw`w-[6px]`} />
                </>
              )}
              <View style={tw`flex-1 mb-sm`}>
                <MenuDishItem
                  dish={dish}
                  highlight={snapshot.isDragging}
                  showMoreOptions={currentDragEditType === DragEditType.OFF && !editingListData}
                  onPress={
                    currentDragEditType === DragEditType.OFF
                      ? () => {
                          goToEditScreen()
                        }
                      : undefined
                  }
                  onOpenSmartModal={dish => {
                    setSmartDishModal(dish)
                  }}
                />
              </View>
            </View>
          </div>
        )}
      </Draggable>
    )
  }

  const getDragDroppableType: (draggableId: string) => DragDroppableType = draggableId => {
    if (listData.categoryOrder.includes(draggableId)) {
      return DragDroppableType.CATEGORY
    }
    if (Object.keys(listData.subCategories).includes(draggableId)) {
      return DragDroppableType.SUBCATEGORY
    }
    return DragDroppableType.DISH
  }

  const onDragEnd: OnDragEndResponder = endResult => {
    const { source, destination, draggableId } = endResult
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return
    setLoading(true)

    switch (getDragDroppableType(draggableId)) {
      case DragDroppableType.DISH:
        const startSubcat = listData.subCategories[source.droppableId]
        const finishSubcat = listData.subCategories[destination.droppableId]
        if (startSubcat === finishSubcat) {
          const newDishIds = [...startSubcat.dishIds]
          newDishIds.splice(source.index, 1)
          newDishIds.splice(destination.index, 0, draggableId)
          const newSubcat: SubCategory = { ...startSubcat, dishIds: newDishIds }
          const newListState: ListData = {
            ...listData,
            subCategories: { ...listData.subCategories, [newSubcat.key]: newSubcat },
          }
          setEditingListData(newListState)
        } else {
          const startDishIds = [...startSubcat.dishIds]
          startDishIds.splice(source.index, 1)
          const newStartSubcat: SubCategory = { ...startSubcat, dishIds: startDishIds }

          const finishDishIds = [...finishSubcat.dishIds]
          finishDishIds.splice(destination.index, 0, draggableId)
          const newFinishSubcat: SubCategory = { ...finishSubcat, dishIds: finishDishIds }

          const newListState: ListData = {
            ...listData,
            subCategories: {
              ...listData.subCategories,
              [newStartSubcat.key]: newStartSubcat,
              [newFinishSubcat.key]: newFinishSubcat,
            },
          }
          setEditingListData(newListState)
        }
        break
      case DragDroppableType.SUBCATEGORY:
        const startCat = listData.categories[source.droppableId]
        const finishCat = listData.categories[destination.droppableId]
        if (startCat === finishCat) {
          const newSubcatKeys = [...startCat.subCatKeys]
          newSubcatKeys.splice(source.index, 1)
          newSubcatKeys.splice(destination.index, 0, draggableId)
          const newCat: Category = { ...startCat, subCatKeys: newSubcatKeys }
          const newListState: ListData = {
            ...listData,
            categories: { ...listData.categories, [newCat.key]: newCat },
          }
          setEditingListData(newListState)
        } else {
          showToast(t('l.cannotMoveSubcategory'), 'ERROR')
          setLoading(false)
        }
        break
      case DragDroppableType.CATEGORY:
        const newCategoryOrder = [...listData.categoryOrder]
        newCategoryOrder.splice(source.index, 1)
        newCategoryOrder.splice(destination.index, 0, draggableId)
        const newListState: ListData = {
          ...listData,
          categoryOrder: newCategoryOrder,
        }
        setEditingListData(newListState)
        break
    }
    setCurrentScrollPosY(scrollerRef.current?.scrollTop ?? 0)
  }

  const addCategory = (newCategory: DishCategory) => {
    if (!menu) return
    const newEditingListData: ListData = { ...listData }
    const newCatKey = newCategory.key
    const dummySubcategory: SubCategory = { name: '', key: `${newCatKey}.others`, dishIds: [] }
    newEditingListData.categoryOrder = [newCatKey, ...newEditingListData.categoryOrder]
    newEditingListData.categories[newCatKey] = {
      name: translateLocal(newCategory, 'name', i18n.language),
      key: newCatKey,
      subCatKeys: [dummySubcategory.key],
    }
    newEditingListData.subCategories[dummySubcategory.key] = dummySubcategory
    setMenu({ ...menu, dishCategories: { ...menu.dishCategories, [newCatKey]: newCategory } })
    setOriginalListData(newEditingListData)
  }

  const addSubcategory = (newSubcategory: DishCategory, addingCatSubcat: string) => {
    if (!menu) return
    const newEditingListData: ListData = { ...listData }
    const newSubcatKey = newSubcategory.key
    newEditingListData.subCategories[newSubcatKey] = {
      key: newSubcatKey,
      name: translateLocal(newSubcategory, 'name', i18n.language),
      dishIds: [],
    }
    newEditingListData.categories[addingCatSubcat] = {
      ...newEditingListData.categories[addingCatSubcat],
      subCatKeys: [newSubcatKey, ...newEditingListData.categories[addingCatSubcat].subCatKeys],
    }
    setMenu({ ...menu, dishCategories: { ...menu.dishCategories, [newSubcatKey]: newSubcategory } })
    setOriginalListData(newEditingListData)
  }

  const saveNewCatSubcat = async (submittedText: string, catSubcatDescription?: string) => {
    if (!restaurant || !menu || !addingCatSubcat || !submittedText || submittedText.length === 0) return
    const text = submittedText.trim()
    const description = catSubcatDescription?.trim()
    if (text.length === 0) return
    try {
      let newCatSubcat: DishCategory
      if (addingCatSubcat === 'category') {
        // adding category
        newCatSubcat = await DishCategoryEntityService.create({
          [getLocalizedField('name', inputLanguage)]: text,
          [getLocalizedField('description', inputLanguage)]: description,
          key: `${restaurant._id}_${text}`,
          restaurantId: restaurant._id,
        })
        const nameTranslations = await DishCategoryEntityService.translate(
          newCatSubcat._id,
          getLocalizedField('name', inputLanguage),
          getAllOtherInputLanguages()
        )
        newCatSubcat = { ...newCatSubcat, ...nameTranslations }
        if (description && description.length > 0) {
          const descriptionTranslations = await DishCategoryEntityService.translate(
            newCatSubcat._id,
            getLocalizedField('description', inputLanguage),
            getAllOtherInputLanguages()
          )
          newCatSubcat = { ...newCatSubcat, ...descriptionTranslations }
        }
        addCategory(newCatSubcat)
      } else {
        // adding subcategory
        const category = menu.dishCategories[addingCatSubcat]
        if (!category) return
        newCatSubcat = await DishCategoryEntityService.create({
          [getLocalizedField('name', inputLanguage)]: text,
          [getLocalizedField('description', inputLanguage)]: description,
          key: `${addingCatSubcat}.${text}`,
          restaurantId: restaurant._id,
          parentId: category._id,
        })
        const nameTranslations = await DishCategoryEntityService.translate(
          newCatSubcat._id,
          getLocalizedField('name', inputLanguage),
          getAllOtherInputLanguages()
        )
        newCatSubcat = { ...newCatSubcat, ...nameTranslations }
        if (description && description.length > 0) {
          const descriptionTranslations = await DishCategoryEntityService.translate(
            newCatSubcat._id,
            getLocalizedField('description', inputLanguage),
            getAllOtherInputLanguages()
          )
          newCatSubcat = { ...newCatSubcat, ...descriptionTranslations }
        }
        addSubcategory(newCatSubcat, addingCatSubcat)
      }

      setAddingCatSubcat(undefined)
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  const renameCatSubcat = async (catId: string, key: string, submittedText: string, descriptionText?: string) => {
    if (!restaurant || !menu || !renamingCatSubcat || !submittedText || submittedText.length === 0) return
    const text = submittedText.trim()
    const description = descriptionText?.trim()

    if (text.length === 0) return
    try {
      const newCatSubcat = await DishCategoryEntityService.update(catId, {
        [getLocalizedField('name', inputLanguage)]: text,
        [getLocalizedField('description', inputLanguage)]: description,
        key,
        _id: catId,
        restaurantId: restaurant._id,
      })

      const newEditingListData: ListData = { ...listData }
      const newSubcatKey = newCatSubcat.key
      const editingCatSubcat: Category | SubCategory =
        newEditingListData.subCategories[newSubcatKey] ?? newEditingListData.categories[newSubcatKey]
      if (!editingCatSubcat) return
      editingCatSubcat.name = translateLocal(newCatSubcat, 'name', inputLanguage)
      const originalDishCategory = menu.dishCategories[newSubcatKey]

      setMenu({
        ...menu,
        dishCategories: { ...menu.dishCategories, [newSubcatKey]: { ...originalDishCategory, ...newCatSubcat } },
      })
      setOriginalListData(newEditingListData)
      setRenamingCatSubcat(undefined)
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  const addDish = (categoryKey: string) => {
    setCurrentScrollPosY(scrollerRef.current?.scrollTop ?? 0)
    navigation.navigate('NewDishScreen', { menuId, categoryKey })
  }

  const DragDropView = useCallback(() => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-categories" type={DragDroppableType.CATEGORY} ignoreContainerClipping>
          {provided => (
            <div style={tw`overflow-scroll`} ref={scrollerRef}>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {currentDragEditType === DragEditType.OFF && <AddCategoryButton />}
                {listData.categoryOrder.map((categoryKey, index) => {
                  const category = listData.categories[categoryKey]
                  const subCategories = category.subCatKeys.map(subCatKey => listData.subCategories[subCatKey])
                  return (
                    <CategoryView key={categoryKey} index={index} category={category} subCategories={subCategories} />
                  )
                })}
                {provided.placeholder as React.ReactNode}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }, [listData, editingListData, currentDragEditType])

  const AddCatSubcatModal = () => {
    const [addingCatSubcatText, setAddingCatSubcatText] = useState<string>()
    const [addingCatSubcatDescriptionText, setAddingCatSubcatDescriptionText] = useState<string>()

    if (!menu) return null

    const unusedCatsSubcats =
      addingCatSubcat !== undefined
        ? Object.keys(menu.dishCategories ?? {})
            .filter(catKey =>
              addingCatSubcat === 'category' ? !catKey.includes('.') : catKey.startsWith(`${addingCatSubcat}.`)
            )
            .filter(catKey =>
              addingCatSubcat === 'category'
                ? !listData.categoryOrder.includes(catKey)
                : !listData.categories[addingCatSubcat].subCatKeys.includes(catKey)
            )
            .filter(
              catKey =>
                !addingCatSubcatText ||
                translateLocal(menu.dishCategories[catKey], 'name', i18n.language)
                  .toLowerCase()
                  .includes(addingCatSubcatText.trim().toLocaleLowerCase())
            )
            .map(catKey => ({
              id: menu.dishCategories[catKey]._id,
              key: catKey,
              label: translateLocal(menu.dishCategories[catKey], 'name', i18n.language),
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : []

    return (
      <BaseModal
        visible={!!addingCatSubcat}
        title={addingCatSubcat === 'category' ? t('l.addCategory') : t('l.addSubcategory')}
        onClose={() => setAddingCatSubcat(undefined)}
        animationType="none"
      >
        <View style={tw`h-[${SCREEN_AVAILABLE_HEIGHT / 2}px] justify-between`}>
          <MeaTextInput
            label={addingCatSubcat === 'category' ? t('l.category') : t('l.subcategory')}
            placeHolder={addingCatSubcat === 'category' ? t('l.category') : t('l.subcategory')}
            showInputLocaleFlag
            autoFocus
            onChangeText={setAddingCatSubcatText}
          />
          {addingCatSubcat !== undefined && (
            <FlatList
              data={unusedCatsSubcats}
              renderItem={({ item }) => (
                <Card
                  style={tw`flex-row mt-md items-center justify-between`}
                  onPress={() => {
                    addingCatSubcat === 'category'
                      ? addCategory(menu.dishCategories[item.key])
                      : addSubcategory(menu.dishCategories[item.key], addingCatSubcat)
                    setAddingCatSubcat(undefined)
                  }}
                >
                  <Text style={tw`textMono font-bold title4`}>{item.label}</Text>
                  <IconButton
                    icon="Minus"
                    size={ButtonSize.Small}
                    variant={ButtonVariant.Danger}
                    onPress={async () => {
                      try {
                        await DishCategoryEntityService.delete(item.id)
                        setMenu({
                          ...menu,
                          dishCategories: Object.fromEntries(
                            Object.entries(menu.dishCategories).filter(entry => entry[0] !== item.key)
                          ),
                        })
                      } catch (e) {
                        if (isAxiosErrorFromDomainWithInternalCode(e, NotAuthorizedCode.CANNOT_DELETE_DISH_CATEGORY)) {
                          return showToast(t('errors.cannotDeleteUsedCategory'))
                        }
                        meaErrorHandler(e, 'UPDATE')
                      }
                    }}
                  />
                </Card>
              )}
              ListEmptyComponent={
                <MeaTextInput
                  placeHolder={t('l.description')}
                  label={t('l.description')}
                  showInputLocaleFlag
                  onChangeText={setAddingCatSubcatDescriptionText}
                  style={tw`mt-md`}
                />
              }
            />
          )}
          <Button
            style={tw`mt-md`}
            state={
              !addingCatSubcatText ||
              addingCatSubcatText.trim().length < CAT_SUBCAT_NAME_MIN_LENGTH ||
              unusedCatsSubcats.some(
                catSubCat => catSubCat.label.toLocaleLowerCase() === addingCatSubcatText.trim().toLocaleLowerCase()
              )
                ? ButtonState.Disabled
                : ButtonState.Default
            }
            size={ButtonSize.Small}
            variant={ButtonVariant.Primary}
            label={t('l.save')}
            onPress={async () => {
              if (!addingCatSubcatText) return
              await saveNewCatSubcat(addingCatSubcatText, addingCatSubcatDescriptionText)
              setAddingCatSubcatText(undefined)
              setAddingCatSubcatDescriptionText(undefined)
            }}
          />
        </View>
      </BaseModal>
    )
  }

  const RenameCatSubcatModal = () => {
    if (!menu || !renamingCatSubcat) return null

    const originalCatSubcat = menu.dishCategories[renamingCatSubcat]
    if (!originalCatSubcat) return null
    const originalTranslatedName = translateLocal(originalCatSubcat, 'name', inputLanguage)
    const originalTranslatedDescription = translateLocal(originalCatSubcat, 'description', inputLanguage)

    const [renamingCatSubcatText, setRenamingCatSubcatText] = useState<string>(originalTranslatedName)
    const [renamingCatSubcatDescriptionText, setRenamingCatSubcatDescriptionText] =
      useState<string>(originalTranslatedDescription)

    return (
      <BaseModal
        visible={!!renamingCatSubcat}
        title={inputLanguage === i18n.language ? t('l.edit') : t('l.translate')}
        onClose={() => setRenamingCatSubcat(undefined)}
      >
        <View style={tw`justify-between`}>
          <MeaTextInput
            placeHolder={t('l.name')}
            label={t('l.name')}
            showInputLocaleFlag
            autoFocus
            initialValue={originalTranslatedName}
            onChangeText={setRenamingCatSubcatText}
          />
          <MeaTextInput
            placeHolder={t('l.description')}
            label={t('l.description')}
            initialValue={translateLocal(originalCatSubcat, 'description', inputLanguage)}
            showInputLocaleFlag
            onChangeText={setRenamingCatSubcatDescriptionText}
            style={tw`mt-md`}
          />
          <Button
            style={tw`mt-md`}
            state={
              !renamingCatSubcatText || renamingCatSubcatText.trim().length < CAT_SUBCAT_NAME_MIN_LENGTH
                ? ButtonState.Disabled
                : ButtonState.Default
            }
            size={ButtonSize.Small}
            variant={ButtonVariant.Primary}
            label={t('l.save')}
            onPress={async () => {
              if (!renamingCatSubcatText) return
              await renameCatSubcat(
                originalCatSubcat._id,
                originalCatSubcat.key,
                renamingCatSubcatText,
                renamingCatSubcatDescriptionText
              )
              setRenamingCatSubcatText('')
              setRenamingCatSubcatDescriptionText('')
            }}
          />
        </View>
      </BaseModal>
    )
  }

  const AddCategoryButton = useCallback(() => {
    return (
      <View style={tw`flex-row`}>
        <MeaTooltip
          uniqueId={TooltipOrderManageMenuScreenTypes.CATEGORY_ADD}
          text={t('tooltips.addCategory')}
          position="bottom"
        >
          <Button
            style={tw`mt-xs mb-xs mx-md h-[${ADD_CAT_SUBCAT_HEIGHT}px]`}
            label={t('l.category')}
            variant={ButtonVariant.SecondaryLight}
            size={ButtonSize.Small}
            icon="Plus"
            onPress={() => {
              if (editingListData) return showToast(t('l.pleaseWait'), 'ERROR')
              setInputLanguage(i18n.language)
              setAddingCatSubcat('category')
            }}
          />
        </MeaTooltip>
      </View>
    )
  }, [editingListData])

  return (
    <Screen
      showBackButton
      showInputLanguageChooser
      padded
      noRestaurantName
      topBarChildren={
        <MeaTooltip
          uniqueId={TooltipOrderManageMenuScreenTypes.ORDER_DISHES_CATSUBCAT}
          text={t('tooltips.orderDishesCatSubcats')}
          position="bottom"
          removeWidthWrapper
        >
          <IconButton
            style={tw`w-[40px] h-[32px]`}
            shape={IconButtonShape.Squircle}
            variant={
              currentDragEditType === DragEditType.DISHES
                ? ButtonVariant.Primary
                : currentDragEditType === DragEditType.CAT_SUBCAT
                ? ButtonVariant.Secondary
                : ButtonVariant.Accent
            }
            size={ButtonSize.Small}
            onPress={() => {
              setCurrentDragEditType(
                getNextStringLiteralEnumValue(DragEditType, currentDragEditType, true) ?? DragEditType.OFF
              )
            }}
            icon={'DragHandle'}
          />
        </MeaTooltip>
      }
    >
      <LoadingIndicator visible={loading} label={t('l.saving')} blurIntensity={100} />
      <View style={tw`flex-row mx-md pb-xs justify-between`}>
        <Text style={tw`title2 textMono`}>
          {currentDragEditType === DragEditType.OFF
            ? t('l.menuManagement')
            : currentDragEditType === DragEditType.DISHES
            ? t('l.orderDishes')
            : t('l.orderCatSubcat')}
        </Text>
      </View>
      <DragDropView />
      <AddCatSubcatModal />
      <RenameCatSubcatModal />
      {smartDishModal && <DishSmartOptionsModal dish={smartDishModal} onClose={() => setSmartDishModal(undefined)} />}
    </Screen>
  )
}
