import { useTw } from '@mea-menu/components'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'
import { DevPage } from '..'
import { config } from '../../config'
import { MeaError } from '../../types'
import { showToast } from '../../utils'

export interface ErrorPageProps {
  error: Error
  resetErrorBoundary?: (...args: Array<unknown>) => void
}

/**
 * To be used for blocking errors
 */
export function ErrorPage({ error, resetErrorBoundary }: ErrorPageProps) {
  const { tw } = useTw()
  const { i18n, t } = useTranslation()
  const [devModeCounter, setDevModeCounter] = useState<number>(0)
  const [devModeEnabled, setDevModeEnabled] = useState<boolean>(false)

  const devModeStep = (clickType: 'short' | 'long') => {
    // ___ _ ___
    if (clickType === 'short') {
      setDevModeCounter(devModeCounter >= 1 && devModeCounter < 2 ? devModeCounter + 1 : 0)
    } else if (clickType === 'long') {
      setDevModeCounter(devModeCounter >= 1 && devModeCounter < 2 ? 0 : devModeCounter + 1)
    }
  }

  useEffect(() => {
    setDevModeEnabled(devModeCounter === 3)
  }, [devModeCounter])

  useEffect(() => {
    if (axios.isAxiosError(error) && error.code && error.config.url) {
      error.message = `${error.message} (${error.code} in ${error.config.url}).`
    }
    if (error && error.hasOwnProperty('message')) {
      let message: string
      if (error instanceof MeaError && !!error.localizedMessage) {
        message = i18n.t(error.localizedMessage)
      } else {
        message = error.message
      }
      showToast(message)
    }
  }, [])

  return (
    <>
      {devModeEnabled ? (
        <DevPage
          exitDevMode={() => {
            setDevModeCounter(0)
          }}
        />
      ) : (
        <View style={tw`flex-1 flex-col justify-center fillBackground`}>
          <Text style={tw`title2 textMono self-center p-[10px] mb-[20px]`}>{t('l.pleaseRescanQrCode')}</Text>
          <Text style={tw`title4 textMono text-center p-[10px]`}>{t('l.pleaseRescanQrCode')}</Text>
          <TouchableOpacity
            style={tw`absolute right-6 bottom-6 w-[50%] h-[50px] justify-end items-end`}
            onPress={() => {
              devModeStep('short')
            }}
            onLongPress={() => {
              devModeStep('long')
            }}
          >
            <Text style={tw`textMono caption2 opacity-50`}>v{config.version}</Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  )
}
