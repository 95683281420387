import { Button, ButtonSize, ButtonVariant, IconNames, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Text, View } from 'react-native'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { Screen } from '../components'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { RecipeRow } from '../components/RecipeRow'
import Wizard from '../components/Wizard'
import { DishAndRecipeEditorFragment } from '../fragments/DishAndRecipeEditorFragment'
import { MenuStackScreenProps } from '../navigation'
import { pop } from '../navigation/Navigator'
import { useMenuStore, useRestaurantStore } from '../store'
import { Recipe } from '../types'
import { capitalizeFirstLetter, getLocalizedField } from '../types/utils'
import { translateLocal } from '../utils'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface NewDishScreenProps {
  categoryKey: string
  menuId: string
}

enum ScreenMode {
  SearchRecipe,
  CreateDish,
}

export function NewDishScreen({ navigation, route }: MenuStackScreenProps<'NewDishScreen'>) {
  const { menuId, categoryKey } = route.params ?? {}

  const { tw } = useTw()
  const { t, i18n } = useTranslation()
  const { restaurant } = useRestaurantStore()
  const { menu } = useMenuStore()

  const [selectedRecipe, setSelectedRecipe] = useState<Partial<Recipe>>()
  const [screenMode, setScreenMode] = useState(ScreenMode.SearchRecipe)

  const StepContent = (): JSX.Element => {
    switch (screenMode) {
      case ScreenMode.SearchRecipe:
        return <RecipeSearchView />
      case ScreenMode.CreateDish:
        return <DishAndRecipeCreateView />
    }
  }

  const RecipeSearchView = () => {
    const [dishName, setDishName] = useState('')
    const [foundRecipes, setFoundRecipes] = useState<Recipe[]>([])

    useEffect(() => {
      if (!dishName) return
      if (dishName.length < 3) return setFoundRecipes([])
      searchRecipes()
    }, [dishName])

    const searchRecipes = async () => {
      if (!restaurant) return
      try {
        const results = await RecipeEntityService.getForMenu({
          restaurantId: restaurant._id,
          menuId,
          includeAlreadyIn: false,
          txtFilter: dishName,
          limit: 20,
          offset: 0,
        })
        const recipes = [...results.restaurantRecipes, ...results.systemRecipes]
        setFoundRecipes(recipes)
      } catch (e) {
        meaErrorHandler(e, 'FETCH')
      }
    }

    return (
      <View>
        <FlatList
          data={foundRecipes}
          renderItem={({ item: recipe }) => (
            <RecipeRow
              recipe={recipe}
              onPressIcon={IconNames.Plus}
              onPress={async () => {
                let selectedRecipe = recipe
                if (
                  selectedRecipe.ingredientsId.length > 0 &&
                  (!selectedRecipe.ingredients || selectedRecipe.ingredients.length === 0)
                ) {
                  selectedRecipe = await RecipeEntityService.getById(selectedRecipe._id)
                }
                if (selectedRecipe.system) selectedRecipe.parentId = selectedRecipe._id
                setSelectedRecipe(selectedRecipe)
                setScreenMode(ScreenMode.CreateDish)
              }}
            />
          )}
          ListHeaderComponent={
            <View>
              <Text style={tw`title2 textMono`}>{t('l.insertDishName')}</Text>
              <View style={tw`flex-row items-baseline`}>
                <Text style={tw`rounded bg-primary px-4 py-1 my-3 text-black font-bold`}>
                  {translateLocal(menu?.dishCategories[categoryKey], 'name', i18n.language)}
                </Text>
              </View>
              <MeaTextInput
                placeHolder={t('l.dishName')}
                initialValue={dishName}
                onChangeText={setDishName}
                autoFocus
              />
            </View>
          }
          scrollEnabled={true}
          ListEmptyComponent={<Text style={tw`my-lg textMono opacity-60`}>{t('l.newDishMessage')}</Text>}
          style={tw`p-sm`}
        />
        {dishName.length > 2 && (
          <Button
            style={tw`mx-md mb-lg`}
            size={ButtonSize.Small}
            variant={ButtonVariant.Secondary}
            label={t('l.createNewDish')}
            onPress={() => {
              setSelectedRecipe({ [getLocalizedField('name', i18n.language)]: capitalizeFirstLetter(dishName.trim()) })
              setScreenMode(ScreenMode.CreateDish)
            }}
          />
        )}
      </View>
    )
  }

  const DishAndRecipeCreateView = () => {
    if (!selectedRecipe) return null
    return (
      <View>
        <Text style={tw`mx-sm mb-md textMono font-bold`}>{t('l.insertMainInfos')}</Text>
        <DishAndRecipeEditorFragment
          dish={{ recipe: selectedRecipe, recipeId: selectedRecipe._id, dishCategory: categoryKey }}
          onDishAndRecipeSaved={() => pop(navigation)}
        />
      </View>
    )
  }

  return (
    <Screen showBackButton noRestaurantName padded scrollableOverflow>
      <Wizard
        totalSteps={2}
        currentStep={screenMode}
        showBack={false}
        showNext={false}
        onGoBack={() => setScreenMode(screenMode - 1)}
        onGoNext={() => setScreenMode(screenMode + 1)}
      >
        <StepContent />
      </Wizard>
    </Screen>
  )
}
