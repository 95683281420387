import { useEffect, useMemo, useState } from 'react'
import { Image, ImageProps, ImageResizeMode, ImageURISource } from 'react-native'
import { Images } from '../../../assets'

export interface SmartImageProps extends ImageProps {
  forceHeight?: number
}

interface Dimension {
  width: number
  height: number
}

export const SmartImage = ({ forceHeight, ...props }: SmartImageProps) => {
  const [dim, setDim] = useState<Dimension>()
  const imageUrl = (props.source as ImageURISource).uri
  const url = imageUrl
    ? imageUrl.includes('base64')
      ? imageUrl
      : `${imageUrl}${forceHeight !== undefined ? `?height=${forceHeight}` : ''}`
    : undefined

  useEffect(() => {
    if (!imageUrl || !url) return
    Image.getSize(url, (width, height) => setDim({ width, height }))
  }, [])

  const resizeMode: ImageResizeMode = useMemo(() => {
    if (!dim) return 'cover'
    return dim.width > dim.height ? 'cover' : 'contain'
  }, [dim])

  return (
    <Image
      {...props}
      source={url ? { uri: `${url}` } : Images.placeholder}
      loadingIndicatorSource={Images.placeholder}
      resizeMode={resizeMode}
    />
  )
}
