import { BaseModal, Button, ButtonSize, ButtonVariant, colors, IconButton, useTw } from '@mea-menu/components'
import * as ImagePicker from 'expo-image-picker'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, FlatList, Text, TouchableOpacity, View } from 'react-native'
import { Browser as SentryBrowser } from 'sentry-expo'
import { Images } from '../../../assets'
import { MediaService } from '../../api/employee/MediaService'
import { useNotificationsStore } from '../../store'
import { useMediaStore } from '../../store/mediaStore'
import { MeaMedia, MeaNotificationType } from '../../types'
import { showToast } from '../../utils'
import { SmartImage } from './SmartImage'

export interface ImageInputProps {
  modalTitle?: string
  imageId?: string
  currentImageUri?: string
  saveImage: (image: MeaMedia) => Promise<void> // TODO better handling if fail to save
  onImageUploaded?: (uri: string, fileName?: string) => void
  onImageRemoved?: () => void
  uploadImage?: (imageUrl: string) => Promise<void>
}

export async function uploadImage(image: ImagePicker.ImagePickerAsset) {
  const { uri, fileName, mimeType } = image
  let file = await fetch(uri)
    .then(r => r.blob())
    .then(blobFile => new File([blobFile], fileName ?? 'image', { type: mimeType }))

  return await MediaService.upload(file)
}

export function ImageInput({ currentImageUri, imageId, modalTitle, onImageRemoved, saveImage }: ImageInputProps) {
  const { tw } = useTw()
  const { t } = useTranslation()

  const { setNotification } = useNotificationsStore()
  const { fetchCoverImage } = useMediaStore()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<ImagePicker.ImagePickerAsset>()
  const [imageWithoutBackground, setImageWithoutBackground] = useState<MeaMedia>()
  const [loadingMessage, setLoadingMessage] = useState<string>()

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      allowsMultipleSelection: false,
      quality: 1,
    })
    if (!result.canceled) {
      setUploadedImage(result.assets[0])
    }
  }

  const removeBackground = async () => {
    if (!imageId) return
    setLoadingMessage(t('l.processingImage'))
    try {
      const imageWithoutBg = await MediaService.removeBackgroundAndCrop(imageId)
      setImageWithoutBackground(imageWithoutBg)
    } catch (error) {
      SentryBrowser.captureException(error)
      setIsModalVisible(false)
    }
    setLoadingMessage(undefined)
  }

  const extractMimeType = (dataUri: string): string | undefined => {
    const mimeTypeMatch = dataUri.match(/^data:([^;]+);base64,/)
    return mimeTypeMatch ? mimeTypeMatch[1] : undefined
  }

  const rotateImage = () => {
    if (!uploadedImage) return
    // Create an image element
    const image = new Image()
    image.src = uploadedImage?.uri

    const mimeType = extractMimeType(uploadedImage.uri)
    if (!mimeType) return

    image.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) return

      canvas.width = image.height
      canvas.height = image.width

      // Move to the center of the canvas
      ctx.translate(canvas.width / 2, canvas.height / 2)

      // Rotate the canvas
      ctx.rotate(Math.PI / 2)

      // Draw the image rotated
      ctx.drawImage(image, -image.width / 2, -image.height / 2)

      // Convert the canvas back to base64
      const rotatedBase64 = canvas.toDataURL(mimeType)
      setUploadedImage({ ...uploadedImage, uri: rotatedBase64 })
    }

    image.onerror = error => {
      return
    }
  }

  const resetUi = () => {
    setImageWithoutBackground(undefined)
    setUploadedImage(undefined)
    setIsModalVisible(false)
    setLoadingMessage(undefined)
  }

  const saveNewImage = async () => {
    // UPLOAD NEW IMAGE
    if (uploadedImage) {
      setLoadingMessage(t('l.savingImage'))
      try {
        const newMedia = await uploadImage(uploadedImage)
        await saveImage(newMedia)
        fetchCoverImage(newMedia._id)
      } catch (error) {
        showToast("L'immagine è troppo grande, riprova o contatta il supporto")
        SentryBrowser.captureException(error)
      }
    }

    // REMOVE BACKGROUND FROM EXISTING IMAGE
    else if (imageWithoutBackground) {
      setLoadingMessage(t('l.savingImage'))
      try {
        await saveImage(imageWithoutBackground)
        fetchCoverImage(imageWithoutBackground._id)
      } catch (error) {
        SentryBrowser.captureException(error)
      }
    }

    // FINALLY RESET UI
    resetUi()
  }

  const deleteImage = useCallback(async () => {
    if (!imageId) return
    setLoadingMessage(t('l.deletingImage'))
    try {
      // await MediaService.delete(imageId)
      onImageRemoved?.()
    } catch (error) {
      SentryBrowser.captureException(error)
    }
    resetUi()
  }, [imageId, onImageRemoved])

  const imageUrl = useMemo(() => {
    if (imageWithoutBackground) return imageWithoutBackground.url
    if (uploadedImage) return uploadedImage.uri
    if (currentImageUri) return currentImageUri
    return Images.placeholder
  }, [uploadedImage, currentImageUri, imageWithoutBackground])

  const hasUpdatedImage = useMemo(() => {
    if (uploadedImage) return true
    if (imageWithoutBackground) return true
    return false
  }, [uploadedImage, imageWithoutBackground])

  return (
    <View>
      <TouchableOpacity onPress={() => setIsModalVisible(true)}>
        <SmartImage source={{ uri: imageUrl }} style={tw`w-full h-30 rounded-xs`} />
        <IconButton
          style={tw`absolute top-sm right-sm rounded-sm`}
          onPress={() => setIsModalVisible(true)}
          icon="Edit"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
        />
      </TouchableOpacity>

      <BaseModal visible={isModalVisible} title={modalTitle ?? ''} onClose={resetUi}>
        <SmartImage
          source={{ uri: imageUrl }}
          loadingIndicatorSource={Images.placeholder}
          resizeMode={'contain'}
          style={tw`w-full h-80 rounded-xs my-xs`}
        />
        <View style={tw`h-40`}>
          {loadingMessage && (
            <>
              <Text style={tw`textMono label text-center mb-sm`}>{loadingMessage}</Text>
              <ActivityIndicator size="large" color={colors.orange[400]} />
            </>
          )}

          {!loadingMessage && (
            <>
              {hasUpdatedImage && (
                <View>
                  <Button
                    label={t('l.rotateImage')}
                    variant={ButtonVariant.Secondary}
                    onPress={() => rotateImage()}
                    size={ButtonSize.Small}
                    style={tw`mt-sm flex-1`}
                  />
                  <Button
                    label={t('l.saveImage')}
                    variant={ButtonVariant.Primary}
                    onPress={saveNewImage}
                    size={ButtonSize.Small}
                    style={tw`mt-sm flex-1`}
                  />
                  <Button
                    label={t('l.cancel')}
                    variant={ButtonVariant.Ghost}
                    onPress={() => {
                      setImageWithoutBackground(undefined)
                      setUploadedImage(undefined)
                      setIsModalVisible(false)
                    }}
                    size={ButtonSize.Small}
                    style={tw`mt-lg`}
                  />
                </View>
              )}
              {!hasUpdatedImage && (
                <FlatList
                  style={tw`mt-sm`}
                  numColumns={2}
                  data={[
                    <Button
                      label={t('l.loadImage')}
                      variant={ButtonVariant.Secondary}
                      onPress={pickImage}
                      size={ButtonSize.Small}
                      style={tw`flex-1 mx-xs mb-sm mb-sm`}
                    />,
                    ...(imageId
                      ? [
                          <Button
                            label={t('l.removeBackground')}
                            variant={ButtonVariant.Secondary}
                            onPress={removeBackground}
                            size={ButtonSize.Small}
                            style={tw`flex-1 mx-xs mb-sm`}
                          />,
                          <Button
                            label={t('l.deleteImage')}
                            variant={ButtonVariant.Danger}
                            onPress={() =>
                              setNotification({
                                cancelText: t('l.cancel'),
                                confirmText: t('l.deleteImage'),
                                title: t('l.deleteImage'),
                                type: MeaNotificationType.CONFIRM,
                                description: t('l.sureToDeleteImageItWillBeLostForEver'),
                                onConfirm: deleteImage,
                              })
                            }
                            size={ButtonSize.Small}
                            style={tw`flex-1 mx-xs mb-sm`}
                          />,
                        ]
                      : []),
                  ]}
                  renderItem={({ item }) => item}
                />
              )}
            </>
          )}
        </View>
      </BaseModal>
    </View>
  )
}
